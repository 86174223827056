<template>
	<div class="animated fadeIn">
		<b-row>
			<b-col>
				<template v-if="!weeksLoading && weeksHasData">
					<b-row>
						<b-col class="col-4">
							<b-button
								v-if="parseInt(weekNumber, 10) > parseInt(weeksData[weeksData.length-1].attributes.number, 10)"
								:class="windowWidth === 'xs' ? 'mb-2' : ''"
								variant="primary"
								class="btn float-left bg-primary-alt"
								@click="changeWeek('previous')">
								{{ translate('previous', { week: (parseInt(weekNumber, 10) - 1) }) }}
							</b-button>
						</b-col>
						<b-col class="col-4">
							<select
								v-model="weekNumber"
								style="height:35px;"
								class="w-100 mx-auto center-dropdown"
								@change="changeWeek('change')">
								<option
									v-for="item in weeksData"
									:key="item.id"
									:value="item.attributes.number">
									{{ `${translate('week')} ${item.attributes.number}` }}
								</option>
							</select>
						</b-col>
						<b-col class="col-4">
							<b-button
								v-if="parseInt(weekNumber, 10) < parseInt(weeksData[0].attributes.number, 10)"
								variant="primary"
								class="btn bg-primary-alt float-right"
								@click="changeWeek('next')">
								{{ translate('next', { week: (parseInt(weekNumber, 10) + 1) }) }}
							</b-button>
						</b-col>
					</b-row>
					<hr>
				</template>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="weeksLoading" />
				<template v-if="!totalsLoading && totalsHasData && !weeksLoading">
					<template>
						<b-row>
							<b-col class="col-6 col-md-9">
								<template v-if="windowWidth === 'xs'">
									<h5>{{ `${translate('week')} ${weekNumber}` }}</h5>
									<h5>{{ currentWeek.to }}</h5>
									<h5>{{ currentWeek.from }}</h5>
								</template>
								<template v-else>
									<h5>{{ `${translate('week_from_to', { week: weekNumber, to: currentWeek.to, from: currentWeek.from })} | ${translate(currentWeek.status) }` }}</h5>
								</template>
							</b-col>
							<b-col class="col-6 col-md-3">
								<b-button
									:disabled="!idsComputed.length"
									variant="success"
									class="btn ml-2 float-right"
									@click="proccessSelected('approve')">
									{{ translate('pay_selected') }}
								</b-button>
								<!-- <b-button
									:disabled="!idsComputed.length"
									variant="danger"
									class="btn float-right"
									@click="proccessSelected('reject')">
									{{ translate('reject_selected') }}
								</b-button> -->
							</b-col>
						</b-row>
						<hr>
						<b-row>
							<b-col class="col-12 col-lg-6">
								<b-card
									no-body
									class="bg-white">
									<div
										class="card-body mb-1 pb-0">
										<div
											class="row w-100"
											style="display: -webkit-inline-box;">
											<div class="col col-11 pr-0">
												<div class="row">
													<div class="col-6 font-weight-bold">
														{{ translate('to_be_paid') }}
													</div>
													<div class="col-6 text-right pl-0">
														{{ totalsData.non_paid }}
													</div>
												</div>
												<div class="row">
													<div class="col-6 font-weight-bold">
														{{ translate('paid2') }}
													</div>
													<div class="col-6 text-right pl-0">
														{{ totalsData.paid }}
													</div>
												</div>
											</div>
											<div class="col col-1">
												<div class="h1 text-muted text-right mb-4">
													<i class="fas fa-coins" />
												</div>
											</div>
										</div>
										<div class="row h4 mb-0">
											<div class="col">
												{{ translate('total') }}
											</div>
											<div class="col text-right">
												{{ totalsData.total }}
											</div>
										</div>
									</div>
									<hr
										class="mx-3 bg-primary-alt"
										style="height: 4px; border-radius: 10px;">
								</b-card>
							</b-col>
						</b-row>
						<hr>
					</template>
				</template>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="totalsLoading"
					:has-data="totalsHasData" />
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table class="table table-hover table-striped">
						<thead>
							<tr>
								<th class="border-top-0">
									<input
										v-model="selectAll"
										type="checkbox">
								</th>
								<th class="border-top-0">
									#
								</th>
								<th class="border-top-0">
									{{ translate('user_id') }}
								</th>
								<th class="border-top-0">
									{{ translate('username') }}
								</th>
								<th class="border-top-0">
									{{ translate('name') }}
								</th>
								<th class="border-top-0 text-right">
									{{ translate('total') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									<input
										v-model="ids[item.id]"
										type="checkbox">
								</td>
								<td class="align-middle">
									{{ `${(((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1))}${item.attributes.is_adjustment ? '*' : ''}` }}
								</td>
								<td class="align-middle">
									<span
										v-if="admin.includes($user.details().type) && item.attributes.user_type === distributor"
										class="badge badge-primary pointer"
										@click="toDashboard({ distributorId: item.id.toString() })">
										{{ item.id }}
									</span>
									<span
										v-else
										class="badge badge-primary">
										{{ item.id }}
									</span>
								</td>
								<td class="align-middle">
									{{ item.attributes.username }}
								</td>
								<td class="align-middle">
									{{ item.attributes.name }}
								</td>
								<td class="align-middle text-right">
									{{ item.attributes.total }}
								</td>
							</tr>
						</tbody>
					</table>
					<!-- <p>{{ translate('foot_note') }}</p> -->
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					:has-data="hasData" />
				<b-pagination
					v-if="pagination.total_pages > 1"
					v-model="pagination.current_page"
					:total-rows="pagination.total"
					:per-page="pagination.per_page"
					align="center"
					@change="getDataFiltered" />
				<div />
			</b-col>
		</b-row>
	</div>
</template>

<script>

import WeeklyPayouts from '@/util/WeeklyPayouts';
import {
	WeeklyCommissions as messages, Commissions, Grids, Products, WeeklyPayout,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import { admin, distributor } from '@/settings/Roles';
import { YMDHMS_FORMAT, MDY_FORMAT } from '@/settings/Dates';
import { PAGINATION } from '@/settings/RequestReply';
import FiltersParams from '@/mixins/FiltersParams';

export default {
	name: 'WeeklyPayouts',
	messages: [messages, Commissions, Grids, Products, WeeklyPayout],
	mixins: [WindowSizes, FiltersParams, DashboardRedirect],
	data() {
		return {
			payout: new WeeklyPayouts(),
			totals: new WeeklyPayouts(),
			pay: new WeeklyPayouts(),
			weeks: new WeeklyPayouts(),
			weekNumber: '',
			alert: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			monthFormat: MDY_FORMAT,
			ids: {},
			selectAll: false,
			admin,
			distributor,
		};
	},
	computed: {
		weeksLoading() {
			return !!this.weeks.data.loading;
		},
		weeksErrors() {
			return this.weeks.data.errors;
		},
		weeksData() {
			try {
				const { data } = this.weeks.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		weeksHasData() {
			if (this.weeksData === null || this.weeksData === undefined) {
				return false;
			}
			const response = Object.keys(this.weeksData).length;
			return !!response;
		},
		totalsLoading() {
			return !!this.totals.data.loading;
		},
		totalsErrors() {
			return this.totals.data.errors;
		},
		totalsData() {
			try {
				const { data } = this.totals.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		totalsHasData() {
			if (this.totalsData === null || this.totalsData === undefined) {
				return false;
			}
			const response = Object.keys(this.totalsData).length;
			return !!response;
		},
		currentWeek() {
			let week = {};
			this.weeksData.forEach((item) => {
				if (parseInt(item.attributes.number, 10) === parseInt(this.weekNumber, 10)) {
					week = {
						from: this.$moment(item.attributes.from_date.date).format(this.monthFormat),
						to: this.$moment(item.attributes.to_date.date).format(this.monthFormat),
						number: item.attributes.number,
						approved: item.attributes.approved,
						status: item.attributes.status,
					};
				}
			});
			return week;
		},
		loading() {
			return !!this.payout.data.loading;
		},
		pagination() {
			return this.payout.data.pagination;
		},
		data() {
			try {
				const { data } = this.payout.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			return !!this.data;
		},
		idsComputed() {
			const ids = [];
			Object.entries(this.ids).forEach(([key, value]) => {
				if (value) {
					ids.push(Number(key));
				}
			});
			return ids;
		},
	},
	watch: {
		selectAll: function watchAll(value) {
			if (value) {
				this.data.forEach((item) => {
					this.$set(this.ids, item.id, true);
				});
			} else if (!value) {
				this.$set(this, 'ids', {});
			}
		},
	},
	mounted() {
		this.selectAll = false;
		this.getData();
	},
	methods: {
		async getData() {
			this.selectAll = false;
			await this.weeks.getWeeks();

			if (this.$route.params.week_id) {
				this.weekNumber = this.$route.params.week_id;
			} else if (!this.$route.params.week_id) {
				this.weekNumber = 1;
				if (this.weeksHasData) {
					this.weekNumber = parseInt(this.weeksData[0].attributes.number, 10);
				}
			}

			await this.totals.getTotals(this.weekNumber);
			await this.payout.getPayouts(this.weekNumber);
		},
		changeWeek(type) {
			let week = parseInt(this.weekNumber, 10);
			if (type === 'previous') week -= 1;
			else if (type === 'next') week += 1;
			this.$router.push({ name: 'WeeklyPayouts', params: { week_id: week, commission: this.active } });
		},
		proccessSelected(type) {
			const config = {
				allowEscapeKey: false,
				allowOutsideClick: false,
			};

			if (!this.idsComputed.length) {
				return null;
			}
			const options = { config: { showCancelButton: true, icon: 'warning' } };

			const trans = { title: this.translate('swal_title'), message: this.translate('swal_message') };
			if (type === 'reject') {
				trans.title = this.translate('swal_reject_title');
				trans.message = this.translate('swal_reject_message');
			}
			this.alert.confirmation(trans.title, trans.message, options, true).then(() => {
				this.alert.loading(this.translate('loading_title'), this.translate('swal_loading_text'), config);
				const payload = { week_number: this.currentWeek.number, user_ids: this.idsComputed };
				this.pay.payout(payload).then(() => {
					this.ids = {};
					this.alert.toast('success', this.translate('payout_success'));
					this.getData();
				}).catch(() => {
					this.alert.toast('error', this.translate('default_error_message'));
				});
			}).catch(() => {});
			return null;
		},
		getDataFiltered(page) {
			this.selectAll = false;
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.payout.getPayouts(this.weekNumber, options);
		},
	},
};
</script>
