import {
	GET_PAYOUTS, GET_PAYOUTS_WEEK_TOTALS, PAYOUT, GET_WEEKS,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class WeeklyPayouts {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getWeeklyPayouts(id) {
		const { method, endpoint } = GET_PAYOUTS;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => Promise.reject(error));
	}

	getTotals(weekNumber) {
		const { method, endpoint } = GET_PAYOUTS_WEEK_TOTALS;

		this.options.data = { week_number: weekNumber };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPayouts(weekNumber, options) {
		const { method, endpoint } = GET_PAYOUTS;

		this.options.data = { week_number: weekNumber, ...options };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	payout(payload) {
		const { method, endpoint } = PAYOUT;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getWeeks() {
		const { method, endpoint } = GET_WEEKS;
		this.options.data = { confirmed: 1 };
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default WeeklyPayouts;
